import create from 'zustand';
import { combine } from 'zustand/middleware';

export interface Video {
  questionID: number;
  duration: number;
  currentTime: number;
  isFinished: boolean;
}

export interface VideoState {
  videos: Video[];
  videoMap: Map<number, Video & { index: number }>;
}

const initialState: VideoState = {
  videos: [],
  videoMap: new Map(),
};

const createVideoMap = (videos: Video[]) => {
  const videoMap = new Map<number, Video & { index: number }>();
  for (let i = 0; i < videos.length; i++) {
    videoMap.set(videos[i].questionID, { ...videos[i], index: i });
  }
  return videoMap;
};

export const useVideoState = create(
  combine(initialState, (set, get) => ({
    setVideo: (questionID: number, duration: number) => {
      const { videos } = get();

      const existingIndex = videos.findIndex(item => item.questionID === questionID);
      if (existingIndex >= 0) {
        videos[existingIndex].duration = duration;
      } else {
        videos.push({ questionID, duration, currentTime: 0, isFinished: false });
      }

      return set({ videos, videoMap: createVideoMap(videos) });
    },

    setCurrentTime: (questionID: number, currentTime: number) => {
      const { videos, videoMap } = get();

      const video = videoMap.get(questionID);
      if (!video) return;

      const isFinished = video.duration - currentTime < 1;

      videos[video.index].currentTime = currentTime;
      videos[video.index].isFinished = videos[video.index].isFinished || isFinished;

      return set({ videos, videoMap: createVideoMap(videos) });
    },
  }))
);
