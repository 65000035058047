import { useCallback } from 'react';
import type { AppProps } from 'next/app';
import { IntlProvider } from 'react-intl';
import { useSessionState } from 'logic/sessionStore';
import { useStudyState } from 'logic/studyStore';

import 'styles/globals.css';

function MyApp({ Component, pageProps }: AppProps) {
  const locale = useStudyState(state => state.document?.languageCode || 'en');
  const messages = useSessionState(useCallback(state => (state.translations || {})[locale] || {}, [locale]));

  return (
    <IntlProvider messages={messages} locale={locale} defaultLocale="en" onError={() => {}}>
      <Component {...pageProps} />
    </IntlProvider>
  );
}
export default MyApp;
