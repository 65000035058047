/**
 * This file should contain constants shared between the web study UI & admin side API & client.
 * They are imported & exported again from platform specific constants files.
 * ! DO NOT add any values here that should not be publicly visible to all users
 */

export enum SurveyQuestionType {
  CHOOSE_ONE = 1,
  CHOOSE_MULTIPLE = 2,
  FREE_TEXT = 3,
  COUNT = 4,
  STAR_RATING = 6,
  PHOTO = 7,
  RANK = 8,
  NPS = 9,
}

export enum SurveyLogicRuleType {
  /** Default rule for question */
  DEFAULT = 1,
  /** Option specific rule (or range for count/star question) */
  OPTION = 3,
}

export enum SurveyLogicNextQuestion {
  /** Jump to end of survey */
  JUMP_TO_END = -10000,
  /** Jump to next question */
  JUMP_TO_NEXT = -20000,
  /** End survey & reject answer */
  REJECT_ANSWER = -30000,
}

export enum SurveyMediaType {
  LINK = 1,
  YOUTUBE_VIDEO = 2,
  IMAGE = 3,
}

export enum SurveyResponseItemState {
  ANSWERED = 1,
  SKIPPED = 2,
  NOT_SHOWN = 3,
}

export enum AttributeGender {
  ATTRIBUTE_ID = 1,
  MALE = 1,
  FEMALE = 2,
  OTHER = 3,
}

export enum WebSurveyUserAttribute {
  /** Panel specific unique ID for this session */
  SESSION_TOKEN = 'sessionToken',
  /** Gender ID (1 = Male, 2 = Female, 3 = Other) */
  GENDER = 'gender',
  /** Age in years */
  AGE = 'age',
  /** Postal code */
  POSTAL_CODE = 'postalCode',
}

/** All supported panel types */
export enum PanelProvider {
  CROWST = 1,
  CINT = 2,
  /*DYNATA = 3,*/
  UNIVERSAL_LINK = 4,
}
