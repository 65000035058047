import create from 'zustand';
import { combine } from 'zustand/middleware';

export interface QueryParts {
  url: string;
  queryParams: { [key: string]: string };
}

export interface SessionState {
  surveyResponseID: number | null;
  queryParts: QueryParts | null;
  timeStarted: Date | null;
  translations: { [lang: string]: { [id: string]: string } } | null;
}

const initialState: SessionState = {
  surveyResponseID: null,
  queryParts: null,
  timeStarted: null,
  translations: null,
};

export const useSessionState = create(
  combine(initialState, set => ({
    setQueryParts: (queryParts: QueryParts) => set({ queryParts }),
    setResponse: (surveyResponseID: number) => set({ surveyResponseID, timeStarted: new Date() }),
  }))
);
