import create from 'zustand';
import { combine } from 'zustand/middleware';
import { MediaType } from 'Constants';
import type { StudyDocument } from 'common/StudyDocument';
import { useVideoState } from './videoStore';

export interface StudyState {
  loadStudyErrorInfo: string | null;
  responseTimeStart: Date | null;
  document: StudyDocument;
}

const initialState: StudyState = {
  loadStudyErrorInfo: null, // Error string returned by api
  responseTimeStart: null,
  document: {
    url: '',
    panelProviderID: 0,
    name: '',
    description: '',
    instructions: '',
    timeoutInMinutes: 0,
    questionCount: 0,
    estimatedTime: 0,
    questions: [],
    companyName: '',
    attributes: {},
    languageCode: 'en',
    showCrowstAppPromo: false,
    requiredAttributes: [],
    isBackDisabled: false,
  },
};

export const useStudyState = create(
  combine(initialState, (set, get) => ({
    setStudyDataLoadError: (loadStudyErrorInfo: string | null) => set({ loadStudyErrorInfo }),
    setStudyData: (study: StudyDocument) => {
      // Preload video info to prevent sudden "disabled" state after Youtube video is ready
      const videos = study.questions
        .map(item =>
          item.media
            .filter(media => media.questionMediaTypeID === MediaType.YOUTUBE_VIDEO)
            .map(video => ({ questionID: item.questionID, video }))
        )
        .flat();

      const { setVideo } = useVideoState.getState();
      for (const video of videos) {
        setVideo(video.questionID, 0);
      }

      set({ document: study });
    },
    setAttributes: (attributes: StudyDocument['attributes']) =>
      set({ document: { ...get().document, attributes: { ...get().document.attributes, ...attributes } } }),
    getQuestion: (questionID: number) => {
      return get().document.questions.find(question => question.questionID === questionID) || null;
    },
  }))
);
